import * as ko from "knockout";
import {auth0, ideaApi, notificationApi, rankingApi, userApi} from "../../api/api-wrapper";
import {Context, Router} from "@profiscience/knockout-contrib-router";
import {
    CommentDto,
    Evaluation,
    EvaluationDto,
    Idea,
    IdeaDto,
    IdeaMember,
    IdeaMemberDto,
    UserAwardLevel,
    UserAwardsDto,
    UserDto,
    UserScore
} from "../../api/generated";
import globalState from "../../global-state";
import {autobind, computed, observable, observableArray, unwrap} from "knockout-decorators";
import {evaluationStatusClass} from "../evaluation/evaluationUtils";
import {FileData} from "../editor/_common";
import {postbox} from "../../components/util/postbox";
import i18nextko from "../../bindings/i18nko";
import '../../components/elements/userNotifications/user-notification-item';
import {UserNotificationViewModel} from "../../components/elements/userNotifications/user-notification-item";
import {isIdeaActive} from "../ideas/ideaUtils";
import {config} from "../../utils/clientConfigWrapper";
import {departmentName} from "../../components/elements/user/userUtil";
import moment = require("moment");
import StateEnum = Idea.StateEnum;
import ImplementEnum = Evaluation.ImplementEnum;
import TypeEnum = Idea.TypeEnum;
import RoleEnum = IdeaMember.RoleEnum;
import {createConfirmModal} from "../../components/elements/modal/modal";
import {auth0LogoutOptions} from "../../utils/auth0Options";

class ViewModelContext extends Context {
    user: UserDto;
    evaluations: EvaluationDto[];
    ideas: IdeaDto[];
    bookmarkedIdeas: IdeaDto[];
    comments: CommentDto[];
    memberships: IdeaMemberDto[];
    awards: UserAwardsDto;
    userScores: UserScore[];
}

class ViewModel {

    /**
     * The user.
     */
    @observable({deep: true, expose: true})
    public user: UserDto;

    /**
     * The user's profile image.
     */
    public imageData: KnockoutObservable<FileData>;

    /**
     * Flag if the upload field is visible.
     */
    public imageUploadVisible: KnockoutObservable<boolean>;

    /**
     * Subscription for image upload.
     */
    public imageUploadSubscription: KnockoutSubscription;

    /**
     * Subscription for excludeFromRankings change.
     */
    public excludeFromRankingsSubscription: KnockoutSubscription;

    /**
     * Subscription for admin change.
     */
    public adminSubscription: KnockoutSubscription;

    /**
     * Subscription for adminHidden change.
     */
    public adminHiddenSubscription: KnockoutSubscription;

    /**
     * The user's evaluations.
     */
    public evaluations: EvaluationDto[];

    /**
     * The user's ideas.
     */
    public ideas: IdeaDto[];

    /**
     * The bookmarked ideas.
     */
    @observableArray({deep: false, expose: true})
    public bookmarkedIdeas: IdeaDto[];

    /**
     * The user comments.
     */
    public comments: CommentDto[];

    /**
     * The user's memberships except with role submitter.
     */
    public memberships: IdeaMemberDto[];

    /**
     * The awards.
     */
    public awards: UserAwardsDto;

    /**
     * The awards which should be shown - where the award level is not 'none'
     */
    public awardsComputed: KnockoutComputed<any[]>;

    /**
     * The user scores.
     */
    public userScores: UserScore[];

    /**
     * Flag whether the user is visiting his own profile page.
     */
    public isMyProfile: KnockoutObservable<boolean>;

    /**
     * Flag whether the user can edit evaluations.
     */
    public canEditEvaluation: KnockoutObservable<boolean>;

    public viewedNotifications: KnockoutObservable<number>;

    public userScoreItemsVisible: KnockoutObservable<number>;

    public allUserScoreItemsVisible: KnockoutObservable<boolean>;

    constructor(ctx: ViewModelContext) {
        this.user = Object.assign({}, {'excludeFromRankings': false, adminHidden: true}, ctx.user);
        this.isMyProfile = ko.observable(globalState.user().id === this.user.id);
        this.canEditEvaluation = ko.observable((this.isMyProfile() || globalState.user().admin));
        this.evaluations = ctx.evaluations.filter(evaluation => {
            // Unpublished (not evaluated by admin) evaluations are visible for the user and admin user only
            if (!evaluation.evaluated && !(this.isMyProfile() || globalState.user().admin)) {
                return false;
            }
            if (!evaluation.expertApproved) {
                return false;
            }
            return true;
        });

        this.memberships = ctx.memberships.filter(ideaMember => ideaMember.accepted == true && ideaMember.role != RoleEnum.Submitter);
        // UserScores except the ones from today sorted by created.
        this.userScores = ctx.userScores
            .filter(score => moment(score.created).isBefore(moment().startOf('day')))
            .sort((eval1, eval2) =>
                (<string><any>eval2.created).localeCompare(<string><any>eval1.created));
        // Ideas of this user and ideas with memberships and role submitter should be merged in one list.
        // see IDW-161
        const submitterIdeas: IdeaDto[] = ctx.memberships.filter(ideaMember =>
            ideaMember.accepted == true && ideaMember.role == RoleEnum.Submitter && ideaMember.idea.state != StateEnum.Submission)
            .map(ideaMember => ideaMember.idea);

        const allUserIdeas = ctx.ideas.concat(submitterIdeas);

        this.ideas = allUserIdeas.filter(idea => idea.created);

        this.bookmarkedIdeas = ctx.bookmarkedIdeas;
        this.comments = ctx.comments;

        this.awards = ctx.awards;
        this.awardsComputed = ko.pureComputed(() =>
            Object.keys(this.awards)
                .filter(key => this.awards[key] != UserAwardLevel.None)
                .map(key => {
                    return {
                        key: key,
                        color: this.awards[key],
                        count: this.user[key + 'Award']
                    }
                }));

        this.imageUploadVisible = ko.observable(false);
        this.imageData = ko.observable({
            dataURL: ko.observable(),
            base64String: ko.observable(),
            file: ko.observable()
        });
        this.imageUploadSubscription = this.imageData().base64String.subscribe(() => {
            console.debug("Image changed, saving new image");
            this.saveImage();
        });
        this.excludeFromRankingsSubscription = unwrap(this.user, 'excludeFromRankings').subscribe(newValue => {
            console.debug("excludeFromRankings changed, saving new value", newValue);
            // convert to boolean to string, due to swagger codegen bug
            if (globalState.user().admin) {
                userApi.putExcludeFromRankings(this.user.id, "" + newValue)
                    .then(value => this.user.excludeFromRankings = value);

            } else {
                userApi.putExcludeFromRankings(globalState.user().id, "" + newValue)
                    .then(value => this.user.excludeFromRankings = value);
            }
        });

        this.adminSubscription = unwrap(this.user, 'admin').subscribe(newValue => {
            console.debug("admin changed, saving new value", newValue);
            userApi.putUserAdmin(this.user.id, "" + newValue)
                .then(value => this.user.admin = value);
        });

        this.adminHiddenSubscription = unwrap(this.user, 'adminHidden').subscribe(newValue => {
            console.debug("adminHidden changed, saving new value", newValue);
            userApi.putHideAdmin(this.user.id, "" + newValue)
                .then(value => this.user.adminHidden = value);
        });

        this.viewedNotifications = ko.observable(globalState.appVars.notificationsLimit || 20);
        this.userScoreItemsVisible = ko.observable(config.userScoreItemsVisible || 5);
        this.allUserScoreItemsVisible = ko.observable(false);
    }

    /**
     * Css class for the award image.
     * @param key
     * @param color
     */
    public awardImageClass(key: string, color: string) {
        return `award-image-${key}--${color}`;

    }

    /**
     * Initial points from old IDW
     */
    @computed
    public get userPointsInitial() {
        let points = this.user.points ? this.user.points : 0;
        let pointsIDWNeu = this.userScores.reduce((prev, cur) => prev + cur.points, 0);
        let pointsIDWAlt = (points >= pointsIDWNeu) ? (points - pointsIDWNeu) : 0;
        return pointsIDWAlt;
    }

    /**
     * User score is visible if
     *
     * - the user is not admin and
     *
     * - the user is watching his profile or
     * - the admin is watching a different user profile
     *
     * An admin doesn't have scores, therefore its not visible on his own profile page.
     */
    public isScoreVisible() {
        return (!this.user.admin &&
            (this.isMyProfile() || globalState.user().admin) &&
            (this.userScores.length > 0 || this.userPointsInitial > 0)
        );
    }

    /**
     * Toogle show all user scure items
     */
    public toggleUserScoreItemsVisible() {
        this.allUserScoreItemsVisible(!this.allUserScoreItemsVisible());
    }

    /**
     * Unset a bookmark for the idea.
     */
    @autobind
    public unsetBookmark(idea: IdeaDto) {
        return ideaApi.unsetIdeaBookmarked(idea.id).then(bookmarked => {
            if (!bookmarked) {
                postbox.addInfo('idea.details.success.unsetBookmark');
                this.bookmarkedIdeas.splice(this.bookmarkedIdeas.indexOf(idea), 1);
            } else {
                postbox.addError('idea.details.error.unsetBookmark');
            }
            return Promise.resolve();
        }).catch(() => {
            postbox.addError('idea.details.error.unsetBookmark');
        });
    }

    @autobind
    public toggleImageUploadVisible() {
        this.imageUploadVisible(!this.imageUploadVisible());
    }

    @autobind
    public saveImage() {
        if (this.isMyProfile() && this.imageData() && this.imageData().base64String()) {
            if (this.imageData().file().type.search(/^image\/.*$/) == -1) {
                postbox.addError('widget.image.error.invalidType');
                return Promise.reject();
            }

            globalState.loading(true);

            userApi
                .putProfileImage({
                    filename: this.imageData().file().name,
                    mainImage: false,
                    mimeType: this.imageData().file().type,
                    data: this.imageData().base64String()
                })
                .then(user => {
                    this.user = user;
                    globalState.user(user);
                    this.toggleImageUploadVisible();
                })
                .catch(err => console.error(err))
                .finally(() => globalState.loading(false));
        }
    }

    /**
     * Get the css class for an evaluation status.
     * @param evaluation
     */
    public evaluationStatusClass(evaluation: EvaluationDto): KnockoutComputed<string> {
        return evaluationStatusClass(evaluation);

    }

    /**
     * Check if the idea is in submission state.
     *
     * @param idea
     */
    public ideaInSubmission(idea: IdeaDto): KnockoutComputed<boolean> {
        return ko.pureComputed(() =>
            idea.state === IdeaDto.StateEnum.Submission
        );
    }

    /**
     * Check if the evaluation has a description.
     *
     * @param evaluation
     */
    public hasImplementationDescription(evaluation: EvaluationDto): boolean {
        return (evaluation.implementationDescription !== undefined && evaluation.implementationDescription.trim().length > 0)
    }

    /**
     * The options for the file upload field.
     */
    public imageFileOptions() {
        return {
            noFileText: i18nextko.t("global.fileUpload.noFileText"),
            buttonText: i18nextko.t("global.fileUpload.button.choose"),
            changeButtonText: i18nextko.t("global.fileUpload.button.change"),
            clearButtonText: i18nextko.t("global.fileUpload.button.clear")
        };
    }

    @autobind
    public dismissNotification(notificationViewModel: UserNotificationViewModel) {
        notificationApi.dismissUserNotification(notificationViewModel.notification.id)
            .then(() => (<any>globalState)._notifications.remove(notificationViewModel.notification));
    }

    @autobind
    public dismissNotifications() {
        notificationApi.dismissUserNotifications()
            .then(() => (<any>globalState)._notifications.removeAll());
    }

    @computed
    public get notifications() {
        return globalState.notifications.filter((notification, index) => index < this.viewedNotifications());
    }

    @autobind
    public readMore() {
        this.viewedNotifications(this.viewedNotifications() + globalState.appVars.notificationsLimit || 20);
    }

    /**
     * Show link to evaluate an idea.
     * @param evaluation
     */
    @autobind
    public showEvaluateLink(evaluation: EvaluationDto) {
        return this.isIdeaActive(evaluation.idea)
            && !evaluation.submitted
    }

    /**
     * Show link to edit an evaluation. Can be edited by the expert user if the evaluation
     * - is submitted
     * - is not evaluated by the admin
     * - is not assessed by the idea owner
     * see IDW-294
     * @param evaluation
     */
    @autobind
    public showEditLink(evaluation: EvaluationDto) {
        return this.isIdeaActive(evaluation.idea)
            && evaluation.submitted
            && !evaluation.evaluated
            && !evaluation.assessed
    }

    /**
     * Show link for implementation description.
     * see IDW-139
     * @param evaluation
     */
    @autobind
    public showImplementationDescriptionLink(evaluation: EvaluationDto) {
        return this.isIdeaActive(evaluation.idea)
            && evaluation.submitted
            && evaluation.evaluated
            && evaluation.implement == ImplementEnum.Implement
            && (evaluation.idea.type == TypeEnum.Decision && !this.hasImplementationDescription(evaluation));
    }

    /**
     * Show details link to an evaluation.
     * @param evaluation
     */
    @autobind
    public showDetailsLink(evaluation: EvaluationDto) {
        return !this.showImplementationDescriptionLink(evaluation)
            && evaluation.submitted
            && evaluation.evaluated
    }

    /**
     * Check whether an idea is active.
     * @param idea
     */
    public isIdeaActive(idea: IdeaDto) {
        return isIdeaActive(idea);
    }

    /**
     * Dispose subscriptions.
     */
    @autobind
    public dispose() {
        this.imageUploadSubscription.dispose();
        this.excludeFromRankingsSubscription.dispose();
        this.adminSubscription.dispose();
        this.adminHiddenSubscription.dispose();
    }

    @autobind
    public logout() {
        auth0
            .logout(auth0LogoutOptions)
            .then(() => console.debug('logged out successfully'))
            .catch(err => console.debug("logout failed", err.message));
    }

    @autobind
    public departmentName(): string {
        return departmentName(this.user.department);
    }

    @autobind
    public deleteUserConfirmation() {
        return createConfirmModal(
            i18nextko.t("profile.edit.delete.confirmText"), i18nextko.t("profile.edit.delete.confirmTitle"),
            i18nextko.t("global.delete"),
            i18nextko.t("global.cancel")
        )
            .then(() => {
                globalState.loading(true);
                return userApi.deleteUser(this.user.id).then(() => {
                    postbox.addSuccess('profile.edit.success.delete');
                    return Router.update('/', {
                        push: true,
                        force: true
                    });
                })
                    .catch(err => {
                        postbox.addError('profile.edit.error.delete');
                        return Promise.reject(err);
                    })
            })
            .catch(err => {
                console.error(err);
            })
            .finally(() => globalState.loading(false));
    }

}

export default <KnockoutLazyPageDefinition>{
    viewModel: ViewModel,
    template: require('./profile.html'),
    componentName: "profile",
    loader: (ctx: ViewModelContext) => {
        document.title = `${document.title} - Profil ${ctx.params && ctx.params.id || ''}`;
        return Promise.all([
            userApi.getUser(ctx.params.id).then((user) => ctx.user = user),
            userApi.getUserAwards(ctx.params.id).then((awards) => ctx.awards = awards),
            userApi.getExpertEvaluations(ctx.params.id).then(evaluations => ctx.evaluations = evaluations),
            userApi.getUserIdeas(ctx.params.id).then(ideas => ctx.ideas = ideas),
            userApi.getUserComments(ctx.params.id).then(comments => ctx.comments = comments),
            userApi.getUserMemberships(ctx.params.id).then(memberships => ctx.memberships = memberships),
            userApi.getBookmarkedIdeas(ctx.params.id).then(ideas => ctx.bookmarkedIdeas = ideas),
            notificationApi.getUserNotifications()
                .then(notifications => globalState.notifications = notifications),
            rankingApi.queryUserScores(undefined, undefined, undefined, ctx.params.id, undefined)
                .then(userScores => ctx.userScores = userScores ? userScores : [])
        ]);
    }
};
